<template>
  <cur-detail :cur_uuid="uuid"></cur-detail>
</template>

<script>
  import CurDetail from "components/course/CurDetail"

export default {
  components: {
    CurDetail
  },
  data() {
    return {
      uuid: '',
    }
  },
  mounted() {
    this.uuid = this.$route.params.uuid
  }
}
</script>

<style lang="scss" scoped>
</style>
